import config from '../config'

const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request(`${config.apiBaseUrl}/Authentication/Authenticate`, {
            method: 'POST',
            body: JSON.stringify({ email:username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if(response.status === 404){
                    throw new Error("Invalid email/password combination!");
                }
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                if(auth && auth.Data.UserRoleName !== "Admin"){
                    throw new Error("You are not authorized to this resource!");
                }
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch((e) => {
                throw new Error(e.message || 'Network error')
            });
    },
    checkAuth: () => localStorage.getItem('auth') ? Promise.resolve(): Promise.reject(),
    checkError: ()=>{
        return Promise.resolve();
    },
    getPermissions: () => {
        // Required for the authentication to work
        return Promise.resolve();
    },
    logout: ()=>{
        const promise = new Promise((resolve)=>{
            localStorage.removeItem('auth');
            resolve();
        })
        return promise;
    }
    // ...
};

export default authProvider;
