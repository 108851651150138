const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
    let [resource, config ] = args;
    try{
        const response = await originalFetch(resource, config);
        if(response.status === 401){
            localStorage.removeItem("auth");
            window.location.href = "/login"
        }
        return response;
    }
    catch(ex){
        console.error("Exception", ex);
        throw ex;
    }
};