export const EntitiesConfig = {
    "package":{
        "packagemodules":{
            getName: (record)=>record.module.name
        }
    },
    "documenttype":{
        "packagedocumenttypes":{
            getName: (record)=>{
                return record.package.name
            }
        }
    }
}