import {useEffect, useState} from 'react';
import {  Admin, Resource } from 'react-admin';
import './App.css';
import authProvider from './DataProviders/AuthProvider'
import dataProvider from './DataProviders/index'
import {UserList, UserShow} from './Pages/User'
import {AccountList, AccountEdit} from './Pages/Account'
import {GenericEntityList} from './Pages/GenericEntity'
import restEntitiesProvider from "./DataProviders/restEntitiesProvider"

function App() {
  const [_, setEntities] = useState([]);
  useEffect(()=>{
    (async()=>{
      const entities = await restEntitiesProvider.getEntities();
      setEntities(entities);
    })();
  },[])
  return (
    <div className="App">
      <Admin authProvider={authProvider} dataProvider={dataProvider}>
         <Resource name="user" list={UserList} show={UserShow}/>
         <Resource name="account" list={AccountList} edit={AccountEdit}/>
         <Resource name="module" list={<GenericEntityList resource="Module"/>} />
         <Resource name="package" list={<GenericEntityList resource="Package"/>} />
         <Resource name="documentTypes" list={<GenericEntityList resource="DocumentType"/>} />
      </Admin>
    </div>
  );
}

export default App;
