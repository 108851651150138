import config from "./config";
import "./interceptors"
import { HttpError } from "react-admin";

const getCommonOptions = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const AccessToken = auth.Data.AccessToken;
  return {
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessToken}`,
      "Accept-Serialization-Casing": "camel-case",
    },
    redirect: "error",
    referrerPolicy: "no-referrer",
  };
};

export const accountService = {
  mergeIntoAccount: async (accountId, mergedIntoAccountId) => {
    var url = `${config.apiBaseUrl}/Account/${accountId}/MergeInto/${mergedIntoAccountId}`;
    const fetchOptions = {
      ...getCommonOptions(),
      method: "POST",
      body: JSON.stringify({}),
    };
    const response = await fetch(url, fetchOptions);
    const data = await response.json();
    if (data && data.error) {
      throw new HttpError(data.error.message, response.status, data.error);
    }
    return {data:data.data};
  },
};
