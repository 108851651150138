import config from "./config";
import "./interceptors"
import { HttpError } from "react-admin";

const getCommonOptions = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const AccessToken = auth.Data.AccessToken;
  return {
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessToken}`,
      "Accept-Serialization-Casing": "camel-case",
    },
    redirect: "error",
    referrerPolicy: "no-referrer",
  };
};

const getListIncludes = {
  "user" : "UserRole, UserPhoneVerification, UserEmailVerification, Accounts",
  "envelope" : "Documents,Account",
  "account" : "Package,Users",
  "document" : "Asset, Envelope, Type",
  "package": "PackageModules, PackageModules.Module",
  "documenttype": "PackageDocumentTypes,PackageDocumentTypes.Package"
}

const dataProvider = {
  getList: async (resource, params) => {
    const { pagination, sort } = params;
    var sortOrders = (sort.order.toLowerCase() === "desc" ? "-": "") +sort.field;
    var includes = getListIncludes[resource.toLowerCase()] || '';
    var url = `${config.apiBaseUrl}/${resource}?pageIndex=${pagination.page}&pageSize=${pagination.perPage}&sortOrders=${sortOrders}&includes=${includes}`;
    const fetchOptions = {
      ...getCommonOptions(),
      method: "GET",
    };
    const response = await fetch(url, fetchOptions);
    const data = await response.json();
    if (data && data.error) {
      throw new HttpError(data.error.message, response.status, data.error);
    } else {
      const result = { data: data.data, total: data.itemsCount };
      return result;
    }
  },
  getOne: async (resource, params) => {
    const { id } = params;
    const url = `${config.apiBaseUrl}/${resource}/${id}`;
    const fetchOptions = {
      ...getCommonOptions(),
      method: "GET",
    };

    const response = await fetch(url, fetchOptions);
    const data = await response.json();
    if (data && data.error) {
      throw new HttpError(data.error.message, response.status, data.error);
    } else return { data: data.data };
  },

  getMany: async   (resource, params) => {
    const { ids } = params;
    var filters = {Field:"Id", Operation:"=", value:ids[0]};
    var url = `${config.apiBaseUrl}/${resource}?pageIndex=1&pageSize=200&filters=${JSON.stringify(filters)}`;
    const fetchOptions = {
      ...getCommonOptions(),
      method: "GET",
    };
    const response = await fetch(url, fetchOptions);
    const data = await response.json();
    if (data && data.error) {
      throw new HttpError(data.error.message, response.status, data.error);
    } else {
      const result = { data: data.data };
      return result;
    }
  },

  create: async (resource, params) => {
    const { data:payload } = params;
    var url = `${config.apiBaseUrl}/${resource}`;
    const fetchOptions = {
      ...getCommonOptions(),
      method: "POST",
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, fetchOptions);
    const data = await response.json();
    if (data && data.error) {
      throw new HttpError(data.error.message, response.status, data.error);
    }
    return {data:data.data};
  },
  update: async (resource, params) => {
    const payload = {};
    console.log("UPDARING", resource, params)
    const { id, data:newData, previousData } = params;
    Object.keys(newData).forEach((key)=>{
      if(previousData[key] != newData[key]){
        payload[key] = newData[key];
      }
    });
    var url = `${config.apiBaseUrl}/${resource}/${id}`;
    const fetchOptions = {
      ...getCommonOptions(),
      method: "PATCH",
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, fetchOptions);
    const data = await response.json();
    if (data && data.error) {
      throw new HttpError(data.error.message, response.status, data.error);
    }
    return { data: data.data };
  },
  delete: async (resource, params) => {
    const {id} = params;
    var url = `${config.apiBaseUrl}/${resource}/${id}`;
    const fetchOptions = {
      ...getCommonOptions(),
      method: "DELETE"
    };
    const response = await fetch(url, fetchOptions);
    const data = await response.json();
    if (data && data.error) {
      throw new HttpError(data.error.message, response.status, data.error);
    }
    return { data: data.data };
  },

  deleteMany: async (resource, params) => {
    const {ids} = params;
    for(let id in ids){
      await dataProvider.delete(resource, {id})
    }
    return {data:ids};
  }
};
export default dataProvider;
