import config from "./config";

export let restEntities = null;
export let schemas = null;

const dataProvider = {
    getEntities: async ()=>{
        if(restEntities){
            return restEntities;
        }
        const url = `${config.apiBaseUrl}/swagger/v1/swagger.json`;
        const fetchOptions = {
            method: "GET",
            Accept: "application/json",
            "Content-Type": "application/json",
        };
        const response = await fetch(url, fetchOptions);
        const data = await response.json();
        restEntities = [];
        schemas = data.components.schemas;
        for(var key in  data.components.schemas._AvailableRESTEntities.properties){
            restEntities.push({
                resource : key,
                actions: data.components.schemas._AvailableRESTEntities.properties[key],
                schema: data.components.schemas[key]
            })
        }
        return restEntities;
    }
}


export default dataProvider;