// in src/posts.js
import * as React from "react";
import { useUpdate, Show, SimpleShowLayout, TextField, Confirm, BooleanField,  TopToolbar, Button, useShowContext } from 'react-admin';

const UserShowActions = (props) => {
    const {id, resource, record} = props;
    const inputRef = React.useRef();
    const [isConfirmOpen, setConfirmOpen] = React.useState(false);
    const [update, { isLoading }] = useUpdate();
    const initLockUser = () => {
        if(!record.isLockedOut){
            const lockoutReason = inputRef.current.value;
            if(lockoutReason === "" || lockoutReason.trim === ""){
                inputRef.current.focus();
                return;
            }
        }
       
        setConfirmOpen(true);
    }
    const handleConfirm = ()=>{
        let lockingReason = null;
        if(!record.isLockedOut){
            lockingReason = inputRef.current.value;
        }
        const newRecord = {...record, isLockedOut: !record.isLockedOut, lockoutReason : !record.isLockedOut?lockingReason:null};

        update(resource, {id, data:newRecord, previousData:record});
        setConfirmOpen(false);
    }
    const handleDialogClose = ()=>{
        setConfirmOpen(false);
    }
    return (
        <TopToolbar>
            {/* Add your custom actions */}
            <div style={{ display: "flex", flexDirection: "row" }}>
                {!record.isLockedOut ? (
                    <>
                <b>Lock user </b>
                <input ref={inputRef} placeholder="Lock reason" /></>
                ):(<b>Unlock user</b>)}
                <Button label={record.isLockedOut?"Unlock":"Lock"} onClick={initLockUser}></Button>
            </div>
            <Confirm
                isOpen={isConfirmOpen}
                loading={isLoading}
                title="User locking"
                content={`Are you sure you want to ${record.isLockedOut?"Unlock":"Lock"} out this user?`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />

        </TopToolbar>
    );
}

export const UserShowLayout = () =>{
    const {
        isLoading,
        error,
        record,
        resource
    } = useShowContext();
    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <>
            <SimpleShowLayout>
                <h2>User Details</h2>
                <TextField source="id" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="phoneNumber" />
                <TextField source="email" />
                <BooleanField source="isLockedOut"/>
                <UserShowActions id={record.id} resource={resource} record={record}/>
            </SimpleShowLayout>
        </>)
}

export const UserShow = () =>(
    <Show >
        <UserShowLayout />
    </Show>
)