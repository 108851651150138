import * as React from "react";
import { List, EditButton, Datagrid, TextField, ChipField, TopToolbar, ExportButton } from 'react-admin';

const ListActions = () => (
    <TopToolbar>
        {/* <SortButton fields={['firstName', 'lastName', 'accountRole.name']} /> */}
        <EditButton />
        <ExportButton maxResults={200} />
    </TopToolbar>
);

export const AccountList = props => (
    <List {...props} actions={<ListActions />} bulkActionButtons={false}>
        <>
            <h2>Accounts list</h2>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="medirentaCustomerNumber" />
                <ChipField label="Package" source="package.name" />
            </Datagrid>
        </>
    </List>
);
