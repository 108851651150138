import * as React from "react";
import Chip from '@material-ui/core/Chip';
import { List, Datagrid, TextField,BooleanField, DateField ,ArrayField, SingleFieldList,FunctionField, TopToolbar, ExportButton } from 'react-admin';
import {restEntities, schemas} from './../../DataProviders/restEntitiesProvider'
import { EntitiesConfig } from "../../DataProviders/entities.config";

const ListActions = (props) => (
    <TopToolbar>
        <ExportButton maxResults={200}/>
    </TopToolbar>
);

export const GenericEntityList = props => {
    if(!restEntities) return null;
    const entity = restEntities.find(a=>a.resource === props.resource);
    if(!entity) return null;

    const getSchema = (schemaPath)=>{
        const ref = schemaPath;
        const schemaName = ref.substring(ref.lastIndexOf("/")+1, ref.length);
        return schemas[schemaName];
    }
    const getNameProperty = (resource, schema, key) => {
        if(EntitiesConfig && EntitiesConfig[resource.toLowerCase()] && EntitiesConfig[resource.toLowerCase()][key.toLowerCase()] && EntitiesConfig[resource.toLowerCase()][key.toLowerCase()].getName){
            const getName = EntitiesConfig[resource.toLowerCase()][key.toLowerCase()].getName;
            return getName;
        }
        if(!schema.properties) return null;
        if(schema.properties["Name"]) return "name";
        if(schema.properties["Title"]) return "title";
        if(schema.properties["FirstName"] && schema.properties["LastName"]) 
            return key ? (record)=> record && record[key] && (record[key].firstName +" "+record[key].lastName)
                        : (record)=> record && (record.firstName +" "+record.lastName)
        if(schema.properties["Id"]) return "id";
        return null;
    }
    return(
    <List {...props} bulkActionButtons={false} actions={<ListActions/>}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            {Object.keys(entity.schema.properties).map((key)=>{
                // no generated id field
                if(key.toLowerCase() === "id") return null;
                // ignore metadata fields
                if(key.toLowerCase() === "datecreated") return null;
                if(key.toLowerCase() === "datemodified") return null;

                const property = entity.schema.properties[key];

                // ignore foreign key properties
                if(property.type === "string" && property.format === "uuid") return null;

                // transform to camelcase by lowering the first letter
                const field = key;
                key = key[0].toLowerCase() + key.substring(1);


                if(property.type === "array"){
                    const schema = getSchema(property.items.$ref);
                    if(!schema) return null;
                    const nameProperty = getNameProperty(entity.resource, schema, key);
                    if(!nameProperty) return null;

                    const renderFc = (typeof(nameProperty) === "function")? 
                                record => { return (<Chip label={nameProperty(record) }></Chip>)}:
                                record => (<Chip label={record[nameProperty] }></Chip>);
                       
                    return (
                        <ArrayField key={key} source={key}>
                            <SingleFieldList>
                                <FunctionField className="chip" key={key} label={field} render={renderFc} />
                            </SingleFieldList>
                        </ArrayField>
                    )
                }
                
                if(property.$ref){
                    const schema = getSchema(property.$ref);
                    if(!schema) return null;
                    const nameProperty = getNameProperty(entity, schema, key);
                    if(!nameProperty) return null;
                    const renderFc = (typeof(nameProperty) === "function")? 
                                nameProperty:
                                record => record && record[key] && record[key][nameProperty];
                    return (
                        <FunctionField key={key} label={field} render={renderFc} />
                    );
                }

                if (property.type === "boolean"){
                    return (
                        <BooleanField key={key} source={key} />
                    );
                }
                if(property.type === "string" && property.format==="date-time"){
                    return (
                        <DateField key={key} source={key}/>
                    );
                }
                return (
                    <TextField key={key} source={key} />
                )
            })}
        </Datagrid>
    </List>
)};
