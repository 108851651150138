// in src/posts.js
import * as React from "react";
import { Edit, Button,useRefresh, useNotify, Toolbar, SaveButton, SimpleForm, TextInput, ReferenceInput, SelectInput, required, useEditContext} from 'react-admin';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import { accountService } from "../../DataProviders/accountService";


const AcountEditToolbar = () => {
    const {record} = useEditContext();
    const buttonClassName = "MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-t039ty-MuiButtonBase-root-MuiButton-root-RaSaveButton-root";
    const [isMergeDialogOpen, setIsMergeDialogOpen] = React.useState(false);
    const [mergeIntoAccountCustomerNumber, setMergeIntoAccountCustomerNumber] = React.useState("");
    const [isMerging, setIsMerging] = React.useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const onMergeIntoAccountCustomerNumberChanged = (e)=>setMergeIntoAccountCustomerNumber(e.target.value);


    const onMergeAccounts = async ()=>{
        setIsMerging(true);
        try{
            await accountService.mergeIntoAccount(record.id, mergeIntoAccountCustomerNumber);
            notify(`Accounts successfully merged`, { type: 'success', autoHideDuration:3000 });
            setIsMerging(false);
            setIsMergeDialogOpen(false);
            refresh();
        }
        catch(ex){
            setIsMerging(false);
            if(ex.status === 404){
                notify(`Could not find an account with the provided customer number`, { type: 'error' });
                return;
            }
            if(ex.status === 409){
                if(ex.body.errorCode === "ACCOUNT_MERGE_INVALID_NAMES"){
                    notify(`The merged account and the target account must have the same first and last name.`, { type: 'error' });
                    return;
                }
                notify(`Cannot merge an account having a customer number`, { type: 'error' });
                return;
            }
            notify(`Accounts could not be merged`, { type: 'error' });
        }
    }
    return (
    <Toolbar style={{display:"flex", gap:"1em"}}>
        <SaveButton />
        {record && !record.mergedIntoAccount && (
        <Button 
            className={buttonClassName}
            style={{ color:"#fff", background:"red"}} 
            size="medium" label="Merge into another Account"
            onClick={()=>setIsMergeDialogOpen(true)}>
        </Button>
)}
        <Dialog open={isMergeDialogOpen} >
            <DialogTitle>Merge into another account</DialogTitle>
            <DialogContent>
                <p>Please provide the account identifier for the account we will merge this account into.</p>
                <input type="text" style={{width:"90%"}} onChange={onMergeIntoAccountCustomerNumberChanged}></input>
                <p>
                    Account merging means moving all data from one account to the other:
                </p>
                <ol style={{display:"flex", flexDirection:"column", gap:"1em"}}>
                    <li>The envelopes will be moved to the other account</li>
                    <li>The users associated with this account, will be associated with the other account</li>
                    <li>This account will be marked for deletion</li>
                    <li>Important! This operation cannot be reverted, except by manually manipulating the database</li>
                </ol>
            </DialogContent>
            <DialogActions>
                <Button 
                    className={buttonClassName}
                    disabled={isMerging}
                    style={{ color:"#fff", background:"red"}} size="medium" label="Merge into another Account" 
                    onClick={onMergeAccounts}>
                </Button>
                <Button 
                    className={buttonClassName}
                    disabled={isMerging}
                    style={{ color:"#fff", background:"darkgray"}} 
                    size="medium" label="Cancel" onClick={()=>setIsMergeDialogOpen(false)}>
                </Button>
            </DialogActions>
        </Dialog>
    </Toolbar>
)}

export const AccountEdit = (props) => {
    return (
    <>
        <Edit {...props} mutationMode="pessimistic">
            <>
                <h2>Edit Account</h2>
                <SimpleForm toolbar={<AcountEditToolbar/>}>
                <TextInput disabled label="Id" source="id" fullWidth/>
                <TextInput source="firstName" validate={required()} fullWidth/>
                <TextInput source="lastName" validate={required()} fullWidth/>
                <ReferenceInput isRequired={true} label="Package" source="packageId" reference="Package" fullWidth>
                    <SelectInput isRequired={true} optionText={"name"} validate={[required()]} fullWidth/>
                </ReferenceInput>
                <TextInput source="medirentaCustomerNumber" fullWidth/>
            </SimpleForm>
            </>
        </Edit>
    </>
)}