import * as React from "react";
import { List, Datagrid, TextField, ChipField, TopToolbar, ExportButton } from 'react-admin';

const ListActions = () => (
    <TopToolbar>
        <ExportButton maxResults={200} />
    </TopToolbar>
);
export const UserList = props => (
    <List {...props} actions={<ListActions />} bulkActionButtons={false}>
        <>
            <h2>Users list</h2>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="email" />
                <TextField source="phoneNumber" />
                <ChipField label="User role" source="userRole.name" />
            </Datagrid>
        </>
    </List>
);
